import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { app } from '../../../base';
import { Auth } from 'aws-amplify';
import { DataStore } from '@aws-amplify/datastore';
import { Users } from '../../../models';
const db = app.firestore();

export default function Library() {
	const [values, setValues] = useState();
	const [search, setSearch] = useState('');
	const [fileUrl, setFileUrl] = React.useState(null);
	const [users, setUsers] = React.useState([]);
	const [manager, setManager] = useState()

	const handle = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
	};
	const fetchUsers = async () => {
		const usersCollection = await db.collection('files').get();

		setUsers(
			usersCollection.docs.map((doc) => {
				return doc.data();
			})
		);



	};

	const filterFunction = (library) => {
		return (
			library.name.toUpperCase().indexOf(search.toUpperCase()) > -1 ||
			library.storefront.toUpperCase().indexOf(search.toUpperCase()) > -1
		);
	};

	const onFileChange = async (e) => {
		const file = e.target.files[0];
		const storageRef = app.storage().ref();
		const fileRef = storageRef.child(file.name);
		await fileRef.put(file);
		setFileUrl(await fileRef.getDownloadURL());
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		let name = e.target.elements.document?.value;
		if (!name || !fileUrl) {
			return;
		}

		try {
			await db.collection('files').doc(name).set({ name: name, link: fileUrl, storefront: manager[0].subsection });
			await db.collection(`${manager[0].subsection}`).doc(name).set({name: name, link: fileUrl});
			alert('Document added!');
			fetchUsers();
		} catch (error) {
			alert('Error:', e.message);
			return;
		}
	};
	const onchange = (e) => setSearch(e.target.value);

	useEffect(() => {
		const getUsersName = async () => {
			try {
				const authorize = await Auth.currentAuthenticatedUser();
				const usersEmail = authorize.attributes.email;

				const usersModel = await DataStore.query(Users, (c) =>
					c.email('eq', usersEmail)
				);

				setManager(usersModel)

			} catch (error) {
				return
			}
		};

		getUsersName();
		fetchUsers();
	}, []);

	return (
		<div className='md:container mx-auto  md:px-28 text-black -mt-32 '>
			<div className='md:container mb-6 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl pt-32 '>
				<div className='h-auto flex flex-col bg-gray-bg1 lg:flex-row'>
					<div className='w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-10 px-16'>
						<input
							type='text'
							placeholder='Document Search'
							className='border-black rounded-lg border-4 mt-4'
							value={search}
							onChange={onchange}
						/>
						<Form onSubmit={onSubmit}>
							<Form.Group className='mb-3' controlId='formBasicEmail'>
								<Form.Label>Title</Form.Label>
								<Form.Control
									name='document'
									type='text'
									placeholder='Enter Title'
									onChange={handle}
								/>
							</Form.Group>

							<input type='file' onChange={onFileChange} />

							<Button className='mt-10' variant='primary' type='submit'>
								Submit
							</Button>
						</Form>
					</div>
					<div className='w-full h-screen overflow-y-auto  max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default'>
						<table className='table-auto border border-black  '>
							<thead>
								<tr>
									<th className='w-auto border text-black border-black'>
										Document
									</th>
									<th className='w-auto border text-black border-black'>
										Storefront
									</th>
									<th className='w-1/4 border text-black border-black '>
										Delete
									</th>
								</tr>
							</thead>
							<tbody className='h-auto'>
								{users.length > 0
									? users
											.sort()
											.filter(filterFunction)
											.map((x, index) => {
												return (
													<tr key={index}>
														<td className='border border-black text-white text-left'>
															<a
																target='_blank'
																rel='noreferrer'
																className='text-black'
																href={x.link}>
																{x.name}
															</a>
														</td>
														<td className='border border-black text-left'>
															{x.storefront}
														</td>
														<td className='border text-black border-black'>
															{x.storefront === manager[0].subsection ? <a
																href='# '
																onClick={(e) => {
																	db.collection('files')
																		.doc(x.name)
																		.delete()
																		.then(() => {
																			alert('Document delete!');
																			fetchUsers();
																		})
																		.catch((e) => alert('Error:', e.message));
																}}>
																<i
																	className='fa fa-trash'
																	aria-hidden='true'></i>
															</a>: null}
														</td>
													</tr>
												);
											})
									: null}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}
