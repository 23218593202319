import React from "react";
import "./gerlach.css";
import { Row, Col, Card } from "react-bootstrap/";
import GerlachPic from "./gerlach_pic.png";
export default function Gerlach() {
  return (
    <div className="mx-auto text-black -mt-32">
      <div className="mb-12 pb-52 bg-white border-2 border-solid shadow-inner-2xl shadow-2xl pt-32">
        <div className="blueOne py-8">
          <div className="text-center uppercase text-white text-4xl">
            John Gerlach - Director, Cervello Global - Germany GmbH
          </div>
        </div>
        <div className="container mx-auto mt-10">
          <Row>
            <Col>
              <Card
                className="mt-6 md:ml-24 border border-2 border-solid shadow-inner-2xl"
                style={{ width: "18rem" }}
              >
                <Card.Img variant="top" src={GerlachPic} />
              </Card>
            </Col>
            <div className="md:mr-24 col-sm-12 col-md-12 col-lg-6 mt-4">
              <p className="text-base text-black">
                John Gerlach has 15 years' experience as a U. S. Marine Corps
                Officer in the Supply Chain Management and Operational Logistics
                fields and continues to serve as a Major in the USMC Reserves.
                John has led teams of representatives from multiple global and
                U.S. Department of Defense logistics enterprise and operational
                organizations, building cohesion and consensus among diverse
                groups of people. His experience and knowledge of the Europe and
                Africa areas, developed through planning, coordination,
                supervision, and evaluation of region-level logistics endeavors,
                has resulted in strong cooperative ties and partnerships with
                multiple entities.
              </p>
              <p className="text-base text-black">
                John's purpose while serving as the Director of Cervello Global
                - Germany is to further develop our growing global customer
                base, sustain and support current and future partnerships, and
                build a cohesive and supportive workforce for our customers
                throughout Germany and the broader Europe and Africa regions.
              </p>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
}
