const subList = [
    'Business Management',
    'CEO',
    'Corporate Security',
    'DMSS',
    'Global Workforce Development',
    'Human Resources',
    'Hypersonics',
    'Logistics Support',
    'Joint Tactical Data Links',
    'Publications and Media',
    'Technologies'
]

export default subList;