import { React } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import config from '../aws-exports';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { login } from '../features/user';
Amplify.configure(config);

export default function SignIn() {

	const dispatch = useDispatch();

	const signInButton = async (e) => {
		e.preventDefault();

		let email = e.target.elements.email?.value;
		let password = e.target.elements.password?.value;

		try {
			const user = await Auth.signIn(email, password);
			dispatch(
				login({
					email: user.attributes.email,
					email_verified: user.attributes.email_verified,
				})
			);
			return (window.location.href = `/Internal-Dashboard`);
		} catch (error) {
			alert(error.message);
			return error;
		}
	};
	return (
		<div className='mx-auto text-black -mt-48'>
			<div className='md:px-64 mb-6 bg-gray-300 border-2 border-solid pt-48'>
				<div className=' mb-32 mt-16 md:mx-auto bg-white rounded-lg border border-primaryBorder py-10 px-16 '>
					<div className='font-bold text-2xl font-medium text-primary mt-4 mb-12 text-center '>
						Cervello Global Employee Login 🔐
					</div>

					<form onSubmit={signInButton}>
						<div>
							<label className="font-bold text-primary text-lg" htmlFor='email'>Email</label>
							<input
								type='email'
								name='username'
								className={`w-full p-3 text-primary border rounded-lg outline-none text-md text-center transition duration-150 ease-in-out mb-4`}
								id='email'
								placeholder='Your Email'
							/>
						</div>
						<div>
							<label className="font-bold text-primary text-lg" htmlFor='password'>Password</label>
							<input
								type='password'
								className={`w-full p-3 text-primary border rounded-lg outline-none text-md text-center transition duration-150 ease-in-out mb-4`}
								id='password'
								name='password'
								placeholder='Your Password'
							/>
						</div>
						<Link className='text-black hover:text-red-700' to='PasswordReset'>
							Reset Password
						</Link>

						<div className='flex justify-center items-center mt-6 text-black'>
							<Button variant='primary' type='submit' size='lg'>
								Login
							</Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
