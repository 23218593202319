import { React, useState, useEffect } from "react";
import { Users, OnBoarding, Managers } from "../models";
import { DataStore } from "@aws-amplify/datastore";
import { Form, Dropdown } from "react-bootstrap/";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Amplify, { Auth } from "aws-amplify";
import subList from "./subsections";
import rankList from "./ranks";
import awsconfig from "../aws-exports";
import "./Login.css";

Amplify.configure(awsconfig);

export default function Login() {
  const [values, setValues] = useState();
  const [manager, setManager] = useState(false);
  const [onboard, setOnboard] = useState(false);
  const [msg, setMsg] = useState("Users");
  const [subSelect, setSubSelect] = useState("Department");
  const [rankSelect, setRankSelect] = useState("Role");
  const [loggedUser, setLoggedUser] = useState("");
  const [randomPassword, setRandomPassword] = useState("");
  const [show, setShow] = useState(false);
  const [skipOnboarding, setSkipOnboarding] = useState(false);
  const [successful, setSuccessful] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const closeSuccessful = () => setSuccessful(false);
  const showSuccessful = () => setSuccessful(true);

  const closeSkip = () => setSkipOnboarding(false);
  const showSkip = () => setSkipOnboarding(true);

  useEffect(() => {
    const generatePassword = () => {
      let length = 8,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }

      setRandomPassword(retVal);
      return retVal;
    };

    const fetchUsers = async () => {
      try {
        const authorize = await Auth.currentAuthenticatedUser();
        setLoggedUser(authorize.attributes.email);
      } catch (error) {
        return;
      }
    };
    fetchUsers();
    if (manager === true) {
      setMsg("Managers");
    } else if (manager === false) {
      setMsg("Users");
    }

    generatePassword();
  }, [manager]);

  const handle = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const toggle = () => {
    if (manager === true) {
      setManager(false);
      setMsg("Users");
    } else {
      setManager(true);
      setMsg("Managers");
      handleShow();
    }
  };

  const toggleSkip = () => {
    if (onboard === true) {
      setOnboard(false);
    } else {
      setOnboard(true);
      showSkip();
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (rankSelect === "Role" || subSelect === "Department") {
      alert("Please select a Department and Role.");
    } else {
      const managersEmail = await DataStore.query(Managers, (c) =>
        c.email("eq", loggedUser)
      );

      let username = e.target.elements.email?.value.trim();
      let password = randomPassword;
      let first_name = e.target.elements.first_name?.value.trim();
      let last_name = e.target.elements.last_name?.value.trim();
      let status = "Internal";
      let rank = rankSelect;
      let subsection = subSelect;
      let title = msg;
      let checklistChoice = onboard;

      try {
        await Auth.signUp({ username, password });
      } catch (error) {
        alert(error.message);
        return;
      }

      try {
        const newOnboarding = await DataStore.save(
          new OnBoarding({
            Paychex: {
              name: "Paychex",
              description:
                "Paychex Flex is an all-in-one solution for all things HR — payroll, learning, retirement, benefits and more.",
              status: true,
              tasks: [
                "Navigate to Paychex Flex",
                "Login using your Paychex account info",
              ],
              objectName: "Paychex",
            },
            Unanet: {
              name: "Unanet",
              description:
                "Unanet is an integrated project management solution which offers tools for resource planning, accounting, workforce collaboration, timesheet and expense reports.",
              status: true,
              tasks: [
                "Navigate to Unanet",
                "Login using your Unanet account info",
                "Complete all training modules. This does not have to be completed at once. You can return to this step.",
              ],
              objectName: "Unanet",
            },
            MS_Office: {
              description:
                "Microsoft Office is a collection of software programs commonly used in an office environment. ",
              name: "MS Office",
              objectName: "MS_Office",
              status: true,
              tasks: [
                "Navigate to Microsoft 365",
                "Login using your Microsoft account info",
                "Check that all the Microsoft Office apps are on your computer. Download any missing Office apps.",
              ],
            },
            Ring_Central: {
              description:
                "Ring Central is an All-in-One Phone, Team Messaging, and Video Conferencing application",
              name: "Ring Central",
              objectName: "Ring_Central",
              status: true,
              tasks: [
                "Navigate to Ring Central",
                "Login using your Ring Central account info",
                "Download the Ring Central app",
                "Open the Ring Central app",
                "Message your manager on Ring Central to confirm your account is active",
              ],
            },
            Security: {
              description:
                "The following tasks will require involvement from your FSO and your manager. Contact your manager and FSO Officer to begin Security onboarding process. You may continue working through the checklist.",
              name: "Security (FSO)",
              objectName: "Security",
              status: true,
              tasks: [
                "Cervello (53PR6) as owning CAGE at TS Level (Corporate Security)",
                "Agreements and Attestations (SF-312), Attestation, NATO Read-in, Briefings",
                "Security, Insider Threat, and Counterintelligence training course and briefing(s)",
                "VARs issues (must have SMO, Security POC/POC Phone /POC Email; Purpose of Visit; Access Level)",
                "Receipt of DD254 from customer (Corporate Security)",
                "Set up CAC appointment",
                "Facility Access Badging",
              ],
            },
            Physical_Items: [
              "Submit a headshot of yourself and order a Cervello Badge ",
              "Request Business Cards",
              "Order Cervello Attire",
            ],
            Benefits_Forms: {
              name: "Benefits",
              description: "Benefits documents.",
              objectName: "Benefits_Forms",
              tasks: [
                "Complete Health Insurance forms",
                "Complete Dental Insurance forms",
                "Complete Retirement forms",
                "Complete Life Insurance Forms",
              ],
              status: true,
            },
            Paychex_Training: {
              name: "Paychex Training",
              description: "Paychex training is a series of modules designed to acquaint you with the Paychex system",
              objectName: "Paychex_Training",
              tasks: [
                "On the Paychex Flex dashboard click on the training section",
                "Complete all training modules",
              ],
              status: true,
            },
            isConfirmed: checklistChoice,
            confirmedForms: true,
            confirmedItems: false,
            CheckList_Completion: checklistChoice,
          })
        );

        if (title === "Managers") {
          await DataStore.save(
            new Managers({
              first_name: first_name,
              last_name: last_name,
              email: username,
              title: title,
              status: status,
              region: "Americas",
              rank: rank,
              subsection: subsection,
              authenticated: false,
              notifications: ["User is created"],
              managersID: managersEmail[0].id,
            })
          );

          await DataStore.save(
            new Users({
              first_name: first_name,
              last_name: last_name,
              email: username,
              title: title,
              status: status,
              region: "Americas",
              OnBoarding: newOnboarding,
              rank: rank,
              subsection: subsection,
              authenticated: false,
              notifications: ["User is created"],
              managersID: managersEmail[0].id,
            })
          );
        } else {
          await DataStore.save(
            new Users({
              first_name: first_name,
              last_name: last_name,
              email: username,
              title: title,
              status: status,
              region: "Americas",
              OnBoarding: newOnboarding,
              rank: rank,
              subsection: subsection,
              authenticated: false,
              notifications: ["User is created"],
              managersID: managersEmail[0].id,
            })
          );
        }

        showSuccessful();
      } catch (error) {
        alert(error.message);
        return;
      }
    }
  };

  return (
    <div className="mx-auto">
      <div className="mb-6 bg-white py-12">
        <div>
          <div className="w-full max-w-md mx-auto md:border-2 py-10 px-16">
            <h3 className="text-2xl font-bold text-blue-900 mt-4 mb-12">
              New Employee Sign-Up
            </h3>
            {/* <button onClick={showSuccessful}>click me</button> */}
            <Form onSubmit={handleFormSubmit}>
              <Form.Group className="mb-3" id="formBasicEmail">
                <Form.Label className="text-blue-900 font-bold">
                  First Name
                </Form.Label>
                <Form.Control
                  name="first_name"
                  required
                  type="text"
                  placeholder="Enter First Name"
                  onChange={handle}
                />
              </Form.Group>
              <Form.Group className="mb-3" id="formBasicFirstName">
                <Form.Label className="text-blue-900 font-bold">
                  Last Name
                </Form.Label>
                <Form.Control
                  name="last_name"
                  required
                  type="text"
                  placeholder="Enter Last Name"
                  onChange={handle}
                />
              </Form.Group>
              <Form.Group className="mb-3" id="formBasicLastName">
                <Form.Label className="text-blue-900 font-bold">
                  Email address
                </Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  required
                  placeholder="Enter email"
                  onChange={handle}
                />
              </Form.Group>
              <Form.Group className="mb-3" id="formBasicManagerInput">
                <Form.Check
                  inline
                  onChange={toggle}
                  label="New user is a Manager"
                  name="title"
                  type="checkbox"
                  defaultChecked={manager}
                  id={`inline-checkbox-2`}
                />
              </Form.Group>
              <Form.Group className="mb-3" id="formBasicManagerInput">
                <Form.Check
                  inline
                  onChange={toggleSkip}
                  label="Skip onboarding process"
                  name="title"
                  type="checkbox"
                  defaultChecked={onboard}
                  id={`inline-checkbox-3`}
                />
              </Form.Group>
              <Form.Group className="mb-3" id="subsection">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic subsection"
                  >
                    {subSelect}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {subList.map((data) => {
                      return (
                        <Dropdown.Item
                          key={data}
                          onClick={() => setSubSelect(data)}
                        >
                          {data}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
              <Form.Group className="mb-3" id="rank">
                <Dropdown>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic rank">
                    {rankSelect}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {rankList.map((data) => {
                      return (
                        <Dropdown.Item
                          key={data}
                          onClick={() => setRankSelect(data)}
                        >
                          {data}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-3"
                type="submit"
              >
                Submit
              </button>
            </Form>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span className="text-red-500 font-bold">
                    IMPORTANT! Please read
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Checking this box will grant the new user Manager privileges.
                These added privileges include:
                <ul className="list-disc">
                  <li>Access to Manager Employee View screen</li>
                  <li>Add new users</li>
                  <li>
                    View onboarding status and contact info for users in their
                    storefront
                  </li>
                  <li>Authenticate new users for intranet login access</li>
                  <li>Confirm onboarding completion for new users</li>
                  <li>Create announcements</li>
                </ul>
                Only check this box if the new user is a Manager and you intend
                for them to have elevated intranet privileges.
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                  I understand
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={skipOnboarding} onHide={closeSkip}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span className="text-red-500 font-bold">
                    IMPORTANT! Please read
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Checking this box will bypass onboarding procedures for the new
                user. They will immediately be taken to the intranet Dashboard
                upon initial login rather than the Onboarding Checklist.
                <br />
                <br />
                Generally this box should only be selected if the user is NOT a
                new employee and has already completed onboarding. If the user
                is a new employee and has not completed onboarding, this box
                should not be checked -- unless otherwise specified, new
                employees should always go through the onboarding procedures.
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={closeSkip}>
                  I understand
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={successful} onHide={closeSuccessful}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <span className="text-blue-900 font-bold">Success!</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Next steps:
                <ol className="list-decimal">
                  <li>
                    The new user will receive a confirmation code at the email
                    address you entered in this form. Ask the user to give you
                    this confirmation code.
                  </li>
                  <li>
                    Navigate to the Manager Employee View screen (at the
                    navbar).
                  </li>
                  <li>
                    In the Authenticate New User section, enter the confirmation
                    code the user gave you in the Confirmation Code field. Click
                    Submit.
                  </li>
                  <li>
                    <span className="font-bold text-red-500">IMPORTANT: </span>
                    It can take up to 10 seconds for the system to process the
                    confirmation code. Do not try clicking the submit button
                    again until you receive a response pop-up.
                  </li>
                  <li>
                    If entered correctly, the system will alert you that the
                    user is authenticated.
                  </li>
                  <li>
                    The new user can now login. They will need to reset their
                    password, which they can do here:
                    https://www.cervello.global/PasswordReset
                  </li>
                </ol>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={closeSuccessful}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}
