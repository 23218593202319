import React from "react";

export default function USOffice() {
  return (
    <div>
      <div className="text-left text-black mt-4">
        <div className="text-left text-blue-900 font-bold">
          Cervello Global HQ
        </div>
        <ul className="text-left text-sm">
          <li>535 Philippe Parkway</li>
          <li>Safety Harbor, FL 35695</li>
          <li>Phone: 727-303-3001</li>
        </ul>
      </div>
    </div>
  );
}

export function POBox() {
  return (
    <div>
      <div className="text-left text-black mt-4">
        <div className="text-left text-blue-900 font-bold">
          P.O. Box 566
        </div>
        <ul className="text-left text-sm">
          <li>Safety Harbor, FL 34695</li>
          <li>United States of America</li>
        </ul>
      </div>
    </div>
  );
}

export function GermanyOffice() {
  return (
    <div>
      <div className="text-left text-black mt-4">
        <div className="text-left text-blue-900 font-bold">
          Cervello Global - Germany GmbH
        </div>
        <ul className="text-left text-sm">
          <li>Alsterwiete 3</li>
          <li>20099 Hamburg</li>
          <li>Germany</li>
        </ul>
      </div>
    </div>
  );
}

export function StuttgartOffice() {
  return (
    <div>
      <div className="text-left text-black mt-4">
        <div className="text-left text-blue-900 font-bold">
          Cervello Global - Stuttgart
        </div>
        <ul className="text-left text-sm">
          <li>U.S. Marine Corps Forces, Europe and Africa</li>
          <li>Panzer Strasse, Building 2901</li>
          <li>United States Army Garrison Panzer Kaserne</li>
          <li>71032 Boblingen, DE</li>
        </ul>
      </div>
    </div>
  );
}

export function AustraliaOffice() {
  return (
    <div>
      <div className="text-left text-black mt-4">
        <div className="text-left text-blue-900 font-bold">
          Cervello Global of Australia, Pty Ltd
        </div>
        <ul className="text-left text-sm">
          <li>Level 14 440 Collins Street</li>
          <li>Melbourne Vic 3000</li>
          <li>Australia</li>
        </ul>
      </div>
    </div>
  );
}

export function DarwinOffice() {
  return (
    <div>
      <div className="text-left text-black mt-4">
        <div className="text-left text-blue-900 font-bold">
          Darwin Office
        </div>
        <ul className="text-left text-sm">
          <li>Serenity Views 404D</li>
          <li>65 Progress Drive</li>
          <li>Nightcliff NT 0810</li>
          <li>Australia</li>
        </ul>
      </div>
    </div>
  );
}

export function KuwaitOffice() {
  return (
    <div>
      <div className="text-left text-black">
        <h2>Kuwait Office</h2>
        <ul>
          <li>Camp Arifjan</li>
          <li>Kuwait APO AE 09366</li>
        </ul>
      </div>
    </div>
  );
}
