import React from "react";
import BoydPic from "./Boyd McMurtrey - Edited.jpg";
import { Row, Col,  Container, Card } from "react-bootstrap/";
export default function Boyd() {
  return (
		<div className='md:container mx-auto  md:px-28 text-black -mt-32'>
			<div className='md:container mb-6 bg-white border-2 border-opacity-300 border-solid rounded-lg shadow-inner-2xl shadow-2xl pt-32 md:h-auto '>
				<Container className='mt-10'>
					<Row>
						<Col>
							<Card className='mt-6' style={{ width: '18rem' }}>
								<Card.Img variant='top' src={BoydPic} />
								<Card.Body className='text-black'>
									<Card.Title>Boyd McMurtrey</Card.Title>
									<Card.Text className='text-center'>
										Vice President, Global Logistics Center of Excellence
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col xs={6} className='mr-24 pt-24'>
							<p className='text-xl text-black'>
								Boyd McMurtrey is a former officer in the United States Marine
								Corps, retiring with 28 years of experience in the logistics
								community. He has served as a commander, executive officer, and
								plans branch head. His assignments have included Marine Corps
								Logistics Command, the North Atlantic Treaty Organization in
								Mons, Belgium, and numerous deployments supporting worldwide
								contingency operations.
							</p>{' '}
							<p className='text-xl text-black'>
								Boyd is Vice President for the Global Logistics Center of
								Excellence, currently functioning as the primary provider of
								global logistics distribution support for the United States
								Marine Corps. With a global footprint that includes employees in
								Germany, Australia, Japan, Kuwait, Singapore, Guam, and the
								United States, Cervello is uniquely positioned to provide
								logistics and distribution expertise anywhere it might be
								required.
							</p>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}
